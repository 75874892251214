<template>
  <v-container fluid>
    <v-card flat style="height: 83vh; overflow:auto;-webkit-overflow-scrolling:touch;padding-top: 8px;" color="#f1f1f1">
      <v-toolbar color="grey darken-4" dark extended flat>
      </v-toolbar>

      <v-card class="mx-auto" max-width="1100" style="margin-top: -64px;" :loading="loading">
        <v-toolbar flat>
          <v-toolbar-title class="black--text">TUS PEDIDOS</v-toolbar-title>

          <v-spacer></v-spacer>

        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>

        <v-data-iterator
            :items="items"
            item-key="name"
            hide-default-footer
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            no-data-text="No hay ordenes para mostrar"
            >
            <template v-slot:default="{ items }">
                <v-row>
                <v-col
                    v-for="order in items"
                    :key="order.id"
                    cols="12"
                    md="6"
                >
                  <v-card
                            class="mx-auto"
                            outlined
                            v-if="datenow"
                            :color="order.attributes.status === 'cancelled' ? 'red lighten-4' : order.attributes.status === 'pre-created' ? 'warning lighten-4' : order.attributes.status !== 'delivered' ? 'green lighten-4' : ''"
                        >
                            <v-progress-linear
                              v-if="order.attributes.discount"
                              color="yellow darken-2"
                              value="100"
                            ></v-progress-linear>
                            <v-list-item @click="modalOrderDatail(order)" class="pa-2">
                            <v-list-item-content class="pb-0">
                                <div class="font-weight-bold text-uppercase mb-0">
                                  <span class="float-left ml-0 mr-2 caption" style="vertical-align: middle; line-height: 38px">
                                    {{ $moment(order.createdAt).format('lll') }}
                                  </span>
                                  <!--<span class="float-left text-caption" style="vertical-align: middle; line-height: 38px; font-size: 0.70rem !important;">#{{ order.id }} </span>-->
                                  <!--<span class="float-left ml-1" style="vertical-align: middle;" v-if="order.attributes.source == 'pos'"><v-chip class="" x-small> POS </v-chip></span>-->
                                  <span class="float-right" style="vertical-align: middle; line-height: 38px;"> <v-icon>mdi-dots-vertical</v-icon></span>
                                  <span class="float-right text-caption" style="vertical-align: middle; line-height: 38px; font-size: 0.65rem !important;" v-if="datenow">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ order.createdAt | moment('from', datenow) }}</span>
                                      </template>
                                      <span>{{ $moment(order.createdAt).format('lll') }}</span>
                                    </v-tooltip>
                                    
                                
                                  </span>
                                </div>
                                <v-list-item-title class="body-2 font-weight-medium">#{{ order.id }} - {{ order.attributes.store.attributes.name }}</v-list-item-title>
                                <v-list-item-subtitle class="caption text-wrap mb-1" v-if="order.attributes.items"> 
                                    <div v-for="(item, index) in getPedidoTextDatails(order.attributes.items, false)" :key="index">
                                        <div class="">
                                            <span class="font-weight-medium">{{ item.name }}</span>
                                            <span class="caption">{{ item.modifiers }}</span>
                                        </div>
                                    </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="font-weight-medium text-wrap"><v-icon v-if="order.attributes.payment.attributes.method == 'digitalTransfer'" class="blue--text">mdi-bank-transfer</v-icon> <v-icon v-else-if="order.attributes.payment.attributes.method == 'cardReader'" class="green--text">mdi-credit-card-wireless</v-icon> <v-icon v-else-if="order.attributes.payment.attributes.method == 'paymentGateway'" class="green--text">mdi-credit-card</v-icon> <v-icon v-else>mdi-cash-marker</v-icon> TOTAL: {{ order.attributes.total - order.attributes.discount | currency }}  </v-list-item-subtitle>
                          
                            </v-list-item-content>



                            </v-list-item>

                             
                                <v-stepper v-model="status_stepper" v-if="order.attributes.status !== 'pre-created'">
                                    <v-stepper-header>
                                    <v-stepper-step v-if="order.attributes.status == 'cancelled'" step="S6" :complete="order.attributes.status == 'cancelled' ? true : false" :rules="[() => false]">Cancelado</v-stepper-step>
                                    <v-divider v-if="order.attributes.status == 'cancelled'"></v-divider>
                                    <v-stepper-step step="S1" :complete="order.attributes.status == 'created' ? true : order.attributes.status == 'preparing' ? true : order.attributes.status == 'delivering' ? true : order.attributes.status == 'delivered' ? true : false">Creado</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="S2" :complete="order.attributes.status == 'preparing' ? true : order.attributes.status == 'delivering' ? true : order.attributes.status == 'delivered' ? true : false">Preparando</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="S3" :complete="order.attributes.status == 'delivering' ? true : order.attributes.status == 'delivered' ? true : false">En camino</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="S4" :complete="order.attributes.status == 'delivered' ? true : false">Entregado</v-stepper-step>
                                    </v-stepper-header>
                                </v-stepper>

                            <v-alert
                                v-else-if="order.attributes.payment.attributes.status !== 'approved'"
                                dense
                                type="info"
                                class="pl-2 pr-2 mb-0 body-2"
                            >
                                <span v-if="order.attributes.payment.attributes.method === 'paymentGateway'">Tu pago está siendo procesado por la pasarela.</span>
                                <span v-else>Pago pendiente, realiza la tranferencia y adjunta el comprobante para completar tu pedido</span>
                            </v-alert>
                            
                            <v-card-actions v-if="order.attributes.status === 'pre-created' && order.attributes.payment.attributes.status !== 'approved' && order.attributes.payment.attributes.method !== 'paymentGateway'">
                            <v-btn outlined small @click="modalPaymentsOption(order)"> <v-icon left>mdi-credit-card-outline</v-icon> Ver opciones de pago</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                </v-col>
                </v-row>
            </template>

            <template v-slot:footer>
                <v-row class="mt-2 pa-2" align="center" justify="center">
                <span class="grey--text d-none d-md-flex">Items por página</span>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        dark
                        text
                        color="primary"
                        class="ml-2 d-none d-md-flex"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                    <v-list-item
                        v-for="(number, index) in itemsPerPageArray"
                        :key="index"
                        @click="updateItemsPerPage(number)"
                    >
                        <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>

                <v-spacer></v-spacer>

                <span
                    class="mr-4 grey--text"
                >
                    Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn
                    fab
                    dark
                    color="blue darken-3"
                    class="mr-1"
                    @click="formerPage"
                    small
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                    fab
                    dark
                    color="blue darken-3"
                    class="ml-1"
                    @click="nextPage"
                    small
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                </v-row>
            </template>

        </v-data-iterator>
          
        </v-card-text>

        <div class="mb-6 d-flex d-md-none"></div>
      </v-card>
    </v-card>

     <!-- Dialogo Order Details -->
    <v-dialog
      v-model="modalOrder"
      v-if="order"
      width="600"
      scrollable
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="title ma-1">#{{ order.id }} </span>
          
          <v-spacer></v-spacer>
          
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                color="grey darken-4"
                >
               <v-icon left>mdi-comment-question</v-icon> Ayuda
                </v-btn>
            </template>
            <v-list>
                <v-list-item target="_blank" :href="`tel:${order.attributes.store.attributes.phoneSMSAlert ? order.attributes.store.attributes.phoneSMSAlert : order.attributes.store.attributes.phone }`">
                    <v-list-item-title> Llamar al comercio </v-list-item-title>
                </v-list-item>
                <v-list-item target="_blank" :href="`https://wa.me/+573193193240/?text=Necesito ayuda con mi pedido ID: ${order.id}`">
                    <v-list-item-title> Contactar a Festy Soporte </v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>
          
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <div class="mb-4" v-if="datenow">{{ $moment(order.createdAt).format('lll') }} - ({{ order.createdAt | moment('from', datenow) }})</div>

          <div class="body-1 text-wrap mb-3 " style="color: black;"> <strong><v-icon>mdi-storefront</v-icon> Tienda:</strong> {{ order.attributes.store.attributes.name }} </div>
          
          <div class="body-1 text-wrap mb-2" style="color: black;">
            <strong><v-icon>mdi-cart</v-icon> Pedido: </strong>
            <div v-for="(item, index) in getPedidoTextDatails(order.attributes.items, false)" :key="index">
              <div class="pl-4 pr-4 pt-1 pb-1">
                  <span class="font-weight-medium">{{ item.name }}</span>
                  <span class="caption">{{ item.modifiers }}</span>
              </div>
            </div>
          </div>
          
          <div class="body-1 text-wrap mb-3 font-weight-medium" style="color: black;" v-if="order.attributes.promoCode"> <strong><v-icon>mdi-tag-multiple-outline</v-icon> Promo:</strong> {{ order.attributes.discount | currency }} - ({{ order.attributes.promoCode.attributes.code }}) </div>
          
          <div class="body-1 text-wrap mb-3 font-weight-medium" style="color: black;"> <strong><v-icon> {{ order.attributes.payment.attributes.method == 'digitalTransfer' ? 'mdi-bank-transfer'  :  order.attributes.payment.attributes.method == 'cardReader' ? 'mdi-credit-card-wireless' : 'mdi-cash-marker' }} </v-icon> Total:</strong> {{ order.attributes.total - order.attributes.discount | currency }} <v-icon v-if="order.attributes.payment.attributes.method == 'digitalTransfer'" @click="modalPaymentMethod = true">mdi-eye</v-icon> </div>

          <div class="body-1 text-wrap mb-4 " style="color: black;" v-if="order.attributes.source == 'pos' && order.attributes.comments"> <strong><v-icon>mdi-cellphone-iphone</v-icon> Teléfono:</strong> {{order.attributes.comments}} </div>
          
          <div class="body-1 text-wrap mb-4 " style="color: black;" v-if="order.attributes.address"> <strong><v-icon>mdi-map-marker</v-icon> Entregar en:</strong> {{ order.attributes.address.attributes.address }} <strong>{{ order.attributes.address.attributes.name ? ' - [' + order.attributes.address.attributes.name + ']' : '' }}</strong> </div>
          
          <div class="body-1 text-wrap mb-4 " style="color: black;" v-else-if="order.attributes.source == 'pos'"> <strong><v-icon>mdi-map-marker</v-icon> Entregar en:</strong> {{ JSON.parse(order.attributes.notes).address }} </div>
          
          <!--<div class="body-1 text-wrap mb-4 " style="color: black;" v-if="order.attributes.rider"> <strong><v-icon :color="order.attributes.riderStatus ? order.attributes.riderStatus == 'orderAccepted' ? 'success' : order.attributes.riderStatus == 'delivering' ? 'info' : 'success' : 'grey lighten-1' ">mdi-bike-fast</v-icon> Domiciliario:</strong> {{ order.attributes.rider.attributes.name }}</div>-->

          <div class="body-1 text-wrap mb-4 " style="color: black;"> <strong><v-icon>mdi-account-cash</v-icon> Valor del domicilio:</strong> {{ order.attributes.deliveryCost | currency }} </div>
          
          <div class="body-1 text-wrap mb-4 " style="color: black;" v-if="order.attributes.notes"> <strong><v-icon>mdi-note-text</v-icon> Notas:</strong> 
          <span> {{ order.attributes.source == 'client' ? order.attributes.notes : order.attributes.source == 'pos' ? JSON.parse(order.attributes.notes).notes : ''}} </span> 
          </div>

          <div class="body-1 text-wrap mb-4 " style="color: black;" v-if="order.attributes.rating"> <strong><v-icon>mdi-star</v-icon> Calificación:</strong> ({{ order.attributes.rating }}) <span v-if="order.attributes.comments">: {{order.attributes.comments}} </span> </div>

          <v-row align="center" justify="center" v-if="order.attributes.rating || order.attributes.status != 'delivered'">
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="status_stepper" v-if="order.attributes.status !== 'pre-created'">
                    <v-stepper-header>
                        <v-stepper-step v-if="order.attributes.status == 'cancelled'" step="S6" :complete="order.attributes.status == 'cancelled' ? true : false" :rules="[() => false]">Cancelado</v-stepper-step>
                        <v-divider v-if="order.attributes.status == 'cancelled'"></v-divider>
                        <v-stepper-step step="S1" :complete="order.attributes.status == 'created' ? true : order.attributes.status == 'preparing' ? true : order.attributes.status == 'delivering' ? true : order.attributes.status == 'delivered' ? true : false">Creado</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="S2" :complete="order.attributes.status == 'preparing' ? true : order.attributes.status == 'delivering' ? true : order.attributes.status == 'delivered' ? true : false">Preparando</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="S3" :complete="order.attributes.status == 'delivering' ? true : order.attributes.status == 'delivered' ? true : false">En camino</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="S4" :complete="order.attributes.status == 'delivered' ? true : false">Entregado</v-stepper-step>
                        </v-stepper-header>
                </v-stepper>
            </v-col>
            
          </v-row>

          <v-row align="center" justify="center" v-else-if="order.attributes.status == 'delivered'">
            <v-col cols="12" class="pa-0 ma-0 text-center">
              <strong>¿Cómo fue tu experiencia?</strong>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0 text-center">
              <v-btn-toggle
                v-model="rating"
                borderless
                tile
                group
              >
                <v-btn large @click="rateOrderDialog(5)" class="mt-0">
                  <v-icon class="text-h4">mdi-emoticon-excited-outline</v-icon>
                </v-btn>

                <v-btn large @click="rateOrderDialog(3)" class="mt-0">
                  <v-icon class="text-h4">mdi-emoticon-neutral-outline</v-icon>
                </v-btn>
                              
              <v-btn large @click="rateOrderDialog(2)" class="mt-0">
                  <v-icon class="text-h4">mdi-emoticon-sad-outline</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="modalOrder = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
         
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo Order Details -->

     <!-- Dialogo Payment options -->
    <v-dialog
      v-model="modalPaymetnTransfer"
      v-if="order"
      width="600"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="title ma-1"> Pago <span class="body-1">({{ order.attributes.total - order.attributes.discount | currency }})</span> </span>
          
          <v-spacer></v-spacer>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                color="grey darken-4"
                >
               <v-icon left>mdi-comment-question</v-icon> Ayuda
                </v-btn>
            </template>
            <v-list>
                <v-list-item target="_blank" :href="`tel:${order.attributes.store.attributes.phoneSMSAlert ? order.attributes.store.attributes.phoneSMSAlert : order.attributes.store.attributes.phone }`">
                    <v-list-item-title> Llamar al comercio </v-list-item-title>
                </v-list-item>
                <v-list-item target="_blank" :href="`https://wa.me/+573193193240/?text=Necesito ayuda con mi pedido ID: ${order.id}`">
                    <v-list-item-title> Contactar a Festy Soporte </v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>
          
        </v-card-title>

        <v-card-text>
            <div class="mb-2"></div>
            <v-row class="pa-2">
                <v-col cols="12" class="body-1 pb-1" v-for="(item, index) in order.attributes.store.attributes.transfersMethods" :key="index">
                    <span class="body-1">Banco: <strong>{{ item.name }}</strong></span>
                    <br />
                    <span class="body-1">Cuenta #: <strong>{{ item.account }}</strong>
                    <v-icon
                        v-if="item && item.account"
                        color="info"
                        class="ml-2"
                        @click="copyAccount(item.account)"
                    >
                        mdi-content-copy
                    </v-icon>
                    </span>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                    <v-file-input
                        v-model="attachMethodPaymentFile"
                        color="primary accent-4"
                        label="Comprobante de pago"
                        placeholder="Clic aquí para adjuntar"
                        outlined
                        prepend-icon
                        prepend-inner-icon="mdi-paperclip"
                        hide-details
                        :show-size="1000"
                        @change="fileChanged"
                    >
                        <template v-slot:selection="{ index, text }">
                            <v-chip
                            v-if="index < 2"
                            color="primary accent-4"
                            dark
                            label
                            small
                            >
                            {{ text }}
                            </v-chip>
                        </template>
                    </v-file-input>
                </v-col>
            </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="modalPaymetnTransfer = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadingAttach"
            :disabled="loadingAttach"
            color="success"
            outlined
            @click="uploadPaymenRecipe(order)"
          >
           <v-icon left>mdi-receipt</v-icon> Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo Payment options -->

    <!-- Dialogo Payment comprobante -->
    <v-dialog
      v-model="modalPaymentMethod"
      v-if="order"
      width="400"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="title ma-1"> Comprobante </span>
          
          <v-spacer></v-spacer>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                color="grey darken-4"
                >
               <v-icon left>mdi-comment-question</v-icon> Ayuda
                </v-btn>
            </template>
            <v-list>
                <v-list-item target="_blank" :href="`tel:${order.attributes.store.attributes.phoneSMSAlert ? order.attributes.store.attributes.phoneSMSAlert : order.attributes.store.attributes.phone }`">
                    <v-list-item-title> Llamar al comercio </v-list-item-title>
                </v-list-item>
                <v-list-item target="_blank" :href="`https://wa.me/+573193193240/?text=Necesito ayuda con mi pedido ID: ${order.id}`">
                    <v-list-item-title> Contactar a Festy Soporte </v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>
          
        </v-card-title>


        <v-card-text v-if="order.attributes.payment.attributes.attachUrl" class="fill-height">
          
        <v-img
          :src="order.attributes.payment.attributes.attachUrl"
          :lazy-src="order.attributes.payment.attributes.attachUrl"
          aspect-ratio="0.5"
          class="grey lighten-2"
          max-width="400"
          max-height="1000"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        </v-card-text>

        <v-card-text v-else>
          El comprobante no ha sido enviado
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            @click="modalPaymentMethod = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo payment comprobante -->

    <!-- Dialogo Rate Order -->
    <v-dialog
      v-model="dialogRateOrder"
      v-if="order"
      width="400"
      scrollable
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="title ma-1"> Calificar </span>
          
          <v-spacer></v-spacer>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                color="grey darken-4"
                >
               <v-icon left>mdi-comment-question</v-icon> Ayuda
                </v-btn>
            </template>
            <v-list>
                <v-list-item target="_blank" :href="`tel:${order.attributes.store.attributes.phoneSMSAlert ? order.attributes.store.attributes.phoneSMSAlert : order.attributes.store.attributes.phone }`">
                    <v-list-item-title> Llamar al comercio </v-list-item-title>
                </v-list-item>
                <v-list-item target="_blank" :href="`https://wa.me/+573193193240/?text=Necesito ayuda con mi pedido ID: ${order.id}`">
                    <v-list-item-title> Contactar a Festy Soporte </v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>
          
        </v-card-title>

        <v-card-text class="pt-4">
          <v-textarea
            v-model="comments"
            outlined
            name="input-7-4"
            hide-details
            label="Comentarios"
            value=""
            auto-grow
            rows="1"
          ></v-textarea>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="dialogRateOrder = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            @click="rateOrder(order.id, rating_value)"
          >
           <v-icon left>mdi-send</v-icon> Calificar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo Rate Order -->

  </v-container>
</template>

<script>
import Parse from "parse";

export default {
  data() {
    return {
        loading: true,
        itemsPerPageArray: [2, 4, 8, 12],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 2,
        items: [],
        datenow: '',
        status_stepper: 0,
        order: '',
        modalPaymetnTransfer: false,
        modalOrder: false,
        attachMethodPaymentFile: [],
        attachMethodPayment: '',
        loadingAttach: false,
        modalPaymentMethod: false,
        rating: false,
        rating_value: 0,
        dialogRateOrder: false,
        comments: ''
    };
  },

  mounted() {
    this.init()
    this.liveQueryConnection();
    this.interval = setInterval(this.time, 1000);
  },

  destroyed() {
    clearInterval(this.interval)
    Parse.LiveQuery.close();
  },

  computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== `Name`)
      },
    },

    methods: {
      time() {
        this.datenow = this.$moment()
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },

      async init(){
          try {
            let orders = await Parse.Cloud.run("getAllOrders");
            this.items = orders

            this.loading = false

        } catch (error) {
            this.$snotify.error(`${error}`, "Ordenes", {
            timeout: 4000,
            preventDuplicates: true,
            });
        }
      },
      
    modalOrderDatail(order){
      this.order = order
      this.modalOrder = true
    },

    modalPaymentsOption(order){
      this.order = order
      this.modalPaymetnTransfer = true
    },

      getPedidoText(items, slice_text){
      let pedido_text = ''
      let pedido_text_temp = ''
      if (items) {
        items.forEach((item, index) => {
          pedido_text += `(${item.attributes.quantity}) - ${item.attributes.name} - (${this.$options.filters.currency(item.attributes.price)} c/u) ${item.attributes.comboOption ? ' + (' + this.$options.filters.currency(item.attributes.comboPrice) + ')' : ''}`
          if (index+1 < items.length) {            
            pedido_text += ', '
          }
        });

        pedido_text_temp = pedido_text

        if (slice_text) {
          pedido_text_temp = pedido_text.substring(0, 150)
          if (pedido_text.length > 150) {
            pedido_text_temp += '....'
          }
        }

        return pedido_text_temp
      }
    },

    getPedidoTextDatails(items, slice_text){
      let pedido_array = []
      if (items) {
        items.forEach((item, index) => {
          pedido_array.push({
            name: `(${item.attributes.quantity}) - ${item.attributes.name} - (${this.$options.filters.currency(item.attributes.price)} c/u) ${item.attributes.comboOption ? ' + (' + this.$options.filters.currency(item.attributes.comboPrice) + ')' : ''}`,
            modifiers: item.attributes.modifiers ? item.attributes.modifiers.length > 0 ? this.modifiersDetails(item.attributes.modifiers, slice_text) : '' : '',
            end: index+1 < items.length ? false : true
          })
        });
        return pedido_array
      }
    },

    modifiersDetails(modifiers, slice_text){
      let pedido_text = ''
      let pedido_text_temp = ''
      modifiers.forEach((item, indx) => {
        if (item.options && item.options.length > 0) {
          let lastOption = false
          item.options.forEach((option, index) => {
            pedido_text += `${option.label} ${option.price ? option.price > 0 ? '+'+this.$options.filters.currency(option.price) : '' : ''}`
            if (index+1 < item.options.length) {            
              pedido_text += ' | '
              lastOption = true
            }
          }, lastOption)

          if (indx+1 < modifiers.length) {
            pedido_text += ' | '
          }
        }
      })

      pedido_text_temp = pedido_text

      if (slice_text) {
        pedido_text_temp = pedido_text.substring(0, 150)
        if (pedido_text.length > 150) {
          pedido_text_temp += '....'
        }
      }

      return pedido_text_temp
    },

      checkIfModifiersOrder(items){
      if (items) {
        //eslint-disable-next-line
        //console.log(items);
        let isModifier = false
        items.forEach((item) => { 
          if(item.attributes.modifiers && item.attributes.modifiers.length > 0){
            isModifier = true
          }
        })
        return isModifier
      }
      return false
    },

    async uploadAttachPaymentMethod(){
      let simge = this.attachMethodPayment.split(",");
      simge = simge[1];
      var parseFile = new Parse.File(
        "paymentOrder",
        { base64: simge },
        this.attachMethodPaymentFile.type
      );
      let file = await parseFile.save(null);
      return file._url
    },

    fileChanged(file) {
		if (file) {
        const fr = new FileReader();
        if (file) {
          fr.readAsDataURL(file);
          fr.addEventListener("load", () => {
            //eslint-disable-next-line
            //console.log(fr.result);
            this.attachMethodPayment = fr.result;
          });
        }
      }
    },
    
    async uploadPaymenRecipe(order){

        this.loadingAttach = true
        
        if (!this.attachMethodPaymentFile || this.attachMethodPaymentFile.length == 0 || !this.attachMethodPaymentFile.type) {
            this.$snotify.info(`Debes seleccionar un archivo para subir. Haz clic sobre el campo "Comprobante de pago"` , 'Comprobante', {
                timeout: 6000,
                preventDuplicates: true
            });
            this.loadingAttach = false
            return;
        }

        switch (this.attachMethodPaymentFile.type) {
            case 'image/png':
                break;
            case 'image/jpeg':
                break;
            case 'image/jpg':
                break;
            default:
                 this.$snotify.info(`El archivo adjunto tiene un formato no válido ${this.attachMethodPaymentFile.type}` , 'Formato', {
                    timeout: 6000,
                    preventDuplicates: true
                });
                this.loadingAttach = false
                return;
        }

        try {
            let params = {
                orderId: order.id,
                attachUrl: this.attachMethodPaymentFile ? this.attachMethodPaymentFile.length == 0 ? '' : this.attachMethodPaymentFile.type ? await this.uploadAttachPaymentMethod() : '' : ''
            }

            if (!params.attachUrl) {
                this.$snotify.error(`El archivo no se ha adjuntado por favor verifica y vuelve a intentarlo` , 'Comprobante', {
                  timeout: 4000,
                  preventDuplicates: true
                });
                this.loadingAttach = false
                return;
            }

            await Parse.Cloud.run("attachVoucherToOrder", params)
            this.modalPaymetnTransfer = false
                
            this.attachMethodPaymentFile = []
            this.attachMethodPayment = ''
            this.loadingAttach = false

            this.init()

            this.$snotify.success(`Archivo subido satisfactoriamente` , 'Método de pago', {
                timeout: 4000,
                preventDuplicates: true
            });
              
            } catch (error) {
               this.$snotify.error(`${error.message}` , 'Método de pago', {
                  timeout: 4000,
                  preventDuplicates: true
                });
            }
      },

      copyAccount(account){
        const txt = document.createElement('textarea');
        document.body.appendChild(txt);
        txt.value = account; // chrome uses this
        txt.textContent = account; // FF uses this
        var sel = getSelection();
        var range = document.createRange();
        range.selectNode(txt);
        sel.removeAllRanges();
        sel.addRange(range);
        if(document.execCommand('copy')){
           this.$snotify.info('La cuenta fue copiada.', {
              timeout: 3000,
              preventDuplicates: true
            });
        }
        document.body.removeChild(txt);
      },

      rateOrderDialog(rate){
        this.rating_value = rate
        this.dialogRateOrder = true

      },

      async rateOrder(order_id, rate){
        if (!rate || !order_id) {
          this.$snotify.info(`Selecciona un calificativo para tu experiencia` , 'Calificación', {
            timeout: 4000,
            preventDuplicates: true
          });
          return;
        }

        if (!this.comments) {
          this.$snotify.info(`Por favor agrega tus comentarios` , 'Comentarios', {
            timeout: 4000,
            preventDuplicates: true
          });
          return;
        }

        let params = {
          order_id : order_id,
          rate: rate,
          comments: this.comments
        }

        try {
          await Parse.Cloud.run("rateOrderPos", params);
          this.dialogRateOrder = false
          this.init()

          this.$snotify.success(`Gracias por tu calificación.` , 'Calificación', {
            timeout: 4000,
            preventDuplicates: true
          });   
                
        } catch (error) {
          this.$snotify.error(`${error}` , 'Calificación', {
            timeout: 4000,
            preventDuplicates: true
          });
        }
      },

      liveQueryConnection() {
        let clientRequest = new Parse.LiveQueryClient({
          applicationId: process.env.VUE_APP_PARSE_SERVER_APPLICATION_ID,
          serverURL: process.env.VUE_APP_SERVER_URL_LIVE_QUERY,
          javascriptKey: process.env.VUE_APP_JS_KEY
        });

        let that = this

        clientRequest.open();

        let query = new Parse.Query("OrderUpdate");
        //query.equalTo("status", 'active');
        //query.descending("createdAt");
        //query.include('assistant');

        let subscription = clientRequest.subscribe(query);

        subscription.on('open', () => {
          //eslint-disable-next-line
          //console.log('connection opened');
        });

        subscription.on('close', () => {
          //eslint-disable-next-line
          //console.log('connection closed');
        });

        subscription.on('error', (error) => {
          //eslint-disable-next-line
          console.log(error);
        });

        subscription.on("create", async order => {
          //eslint-disable-next-line
          //console.log('create ' + order.attributes.object);
          let order_current = that.items.filter(item => item.id === order.attributes.object)
          //console.log(order_current);
          if (order_current && order_current.length > 0) {
            this.page = 1
            this.init()
          }
        });
      }

    },

};
</script>

<style lang="css" scope>

.card {
  display: flex;
  min-width: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

/*.card::-webkit-scrollbar {
  display: none;
}*/

.card--content {
  min-width: 390px;
  max-width: 390px;
  margin: 5px;
  padding: 5px;
}

.circle {
	width: 38px;
	height: 38px;
	background: teal;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	border-radius: 50px;
  border: 1px solid black;
}
</style>